<template>
  <div>
    <Modal class="modal-distribution" v-if="isOpen">
      <template v-slot:headline>クーポン個別配布</template>
      <template v-slot:body>
        <FormRow>
          <template v-slot:label>
            顧客 user ID を入力
            <div v-if="isOverCustomerLimit" class="text-count text-red">1000件より多くの顧客を選択できません。</div>
            <div v-if="isDraftDone" class="text-count text-green">顧客を下書き保存しました。</div>
            <div class="text-count text-red">{{ selectedCustomerCount }}件選択済</div>
          </template>
          <template v-slot:content>
            <div class="form-content-row mt-3">
              <div class="form-icon">
                <input
                  class="form-control"
                  type="text"
                  v-trim
                  v-number="'numberAndCharC'"
                  name="numberLike"
                  placeholder="user IDを入力して検索"
                  v-model="searchForm.numberLike"
                  @keyup="searchCustomer"
                  @blur="handleBlur"
                />
              </div>
            </div>
          </template>
        </FormRow>
        <section class="section">
          <div class="row">
            <div class="col">
              <Panel type="board" :padding="false">
                <template v-slot:body>
                  <div class="scrollX">
                    <TableWithCheckbox
                      :storeModule="storeModule"
                      :dataTable="dataTable"
                      :count="customerCount"
                      :labels="labels"
                      :currentCheckedItems="selectedCustomerIds"
                      @select-items="handleSelectedItems"
                      ref="table"
                    ></TableWithCheckbox>
                  </div>
                </template>
                <template v-slot:footer>
                  <PaginationLog
                    ref="pagination"
                    :listLength="customerCount"
                    :searchConditions="searchConditions"
                    :subdomain="subdomain"
                    :modulePath="modulePath"
                    noGetPaginationList
                  ></PaginationLog>
                </template>
              </Panel>
            </div>
          </div>
        </section>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item pos-start">
            <ActionButton
              :disabled="!isSaveDraftEnabled"
              class="btn btn-lg btn-gray"
              :handle-submit="saveDraft"
              button-text="下書き保存"
            />
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-white"
              :handle-submit="cancelDistribution"
              button-text="キャンセル"
            />
          </li>
          <li class="listGrid-item">
            <ActionButton
              :disabled="!selectedCustomerCount"
              class="btn btn-lg btn-green"
              :handle-submit="openConfirmation"
              button-text="個別配布する"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeConfirmationModal" v-if="confirmationModal">
      <template v-slot:headline>クーポン個別配布の確認</template>
      <template v-slot:body>
        <p class="description-csv">{{ confirmationMessage }}</p>
        <ul class="listGrid custom-listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="closeConfirmationModal"
            >キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-green"
              :handle-submit="startToDistribute"
              button-text="配布する"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
import { breakLineWithComma, endline } from '@/helpers/formatData';
//component
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import Modal from '@/components/Modal.vue';
import FormRow from '@/components/FormRow.vue';
//constants
import { StatusConstants } from '@/constants/status';
import { CommonConstants } from '@/constants/common'
//mixins
import importStatus from '@/mixins/plugin/importStatus';

export default {
  name: 'EventCoupon',
  props: ['isOpen', 'searchCoupon'],
  data: function() {
    return {
      modulePath: 'customer/getCustomerList',
      storeModule: 'customer',
      searchFields: ['numberLike'],
      searchConditions: {},
      selectedItems: {
        allFlag: false,
        id: [],
      },
      initialSearchConditions: {
        type: StatusConstants.customerType.normal.value,
        forCoupon: 1,
        validFlag: true
      },
      typingInterval: null,
      draftId: "",
      couponCount: 0,
      selectedCustomerCount: 0,
      selectedCustomerIds: [],
      confirmationModal: false,
      importStatusOptions: {
        type: 'coupon_distribution',
        resetCheckbox: true,
        isDownloadCsv: true,
        isCountMessage: false,
        canUseAction: 'coupon/checkUseCouponManual',
      },
      isDraftDone: false
    };
  },
  components: {
    FormRow,
    TableWithCheckbox,
    PaginationLog,
    Modal,
  },
  mixins: [modal, search, importStatus],
  computed: {
    ...mapGetters({
      customerList: 'customer/customerList',
      customerCount: 'customer/customerCount',
      isClientChild: 'auth/isClientChild',
      hasShopRole: 'auth/hasShopRole',
      hasSmsParentEvent: 'event/hasSmsParentEvent',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    labels() {
      return [
        { isCheckbox: true },
        { key: 'number', name: 'user ID', tdClass: 'pre-line dataTable-break mw-130', },
        { key: 'name', name: 'お名前', tdClass: 'pre-space' },
        { key: 'email', name: this.hasSmsParentEvent ? 'メールアドレス/携帯電話番号' : 'メールアドレス', tdClass: 'pre-line' },
      ];
    },
    dataTable() {
      return (this.customerList || []).map((item) => {
        return {
          ...item,
          number: item?.numberPut ? breakLineWithComma((item.numberPut + ',') + item.number) : item.number,
          name: endline(item?.nameSei + ' ' + item?.nameMei, 21),
          email: !this.hasSmsParentEvent ? item?.email : (item?.email && item?.mobilephone ? breakLineWithComma(item.email + ',' + item?.mobilephone) : (item?.email || item?.mobilephone)),
        };
      })
    },
    confirmationMessage() {
      if (this.couponCount > 1) {
        return `${this.couponCount}個のクーポンを、${this.selectedCustomerCount}人に配布しますがよろしいですか？`
      }
      return `${this.selectedCustomerCount}人に配布しますがよろしいですか？`
    },
    isSaveDraftEnabled() {
      return (this.selectedItems.allFlag || this.selectedItems.id.length > 0) && this.searchForm.numberLike && this.customerCount
    },
    isOverCustomerLimit() {
      return this.selectedCustomerCount > 1000;
    }
  },
  mounted () {
    this.resetList()
  },
  watch: {
    isImportDisabled(newValue) {
      this.$emit('handleImportDisabled', newValue);
    },
    isOpen(val) {
      if (val) {
        this.resetData();
      }
    }
  },
  methods: {
    handleSelectedItems(nVal) {
      this.selectedItems = {
        allFlag: nVal.allFlag,
        id: nVal.ids,
      };
    },
    handleBlur(e) {
      if (!CommonConstants.NUMBER_AND_C_VALIDATION_REGEX.test(e.target.value)) {
        e.target.value = '';
        this.searchForm.numberLike = '';
      }
    },
    closeDistributionModal() {
      this.$emit('update:isOpen', false);
      this.resetData();
    },
    closeConfirmationModal() {
      this.closeModal('confirmationModal')
    },
    cancelDistribution() {
      this.clearDraft();
      this.closeDistributionModal();
    },
    clearDraft() {
      if (this.draftId) {
        return this.$store.dispatch('coupon/deleteDraftCustomers', {
          subdomain: this.subdomain,
          draftId: this.draftId,
        });
      }
    },
    async saveDraft() {
      if (!this.isSaveDraftEnabled) return
      if (this.selectedItems.allFlag || this.selectedItems.id.length > 0) {
        let result;
        const params = {
          subdomain: this.subdomain,
          searchCustomer: {
            target: this.selectedItems.id,
            allFlag: this.selectedItems.allFlag,
            searchCondition: {
              ...this.initialSearchConditions,
              ...this.searchForm
            },
            unCheckAll: this.$refs.table.isAllUnchecked
          },
        }
        if (this.draftId) {
          result = await this.$store.dispatch('coupon/updateDraftCustomers', {
            ...params,
            draftId: this.draftId,
            uncheck: this.$refs.table.uncheckedItems
          });
        } else {
          result = await this.$store.dispatch('coupon/createDraftCustomers', {
            ...params,
            searchCoupon: {
              ...this.searchCoupon,
            },
          });
        }
        if (result) {
          this.draftId = result.id
          this.couponCount = result.couponCount || 0
          this.selectedCustomerCount = result.customerCount || 0
          this.selectedCustomerIds = result.customerIds
          if (!this.isOverCustomerLimit) {
            this.resetList();
            this.isDraftDone = true;
            setTimeout(() => {
              this.isDraftDone = false;
            }, 3000);
          }
        }
      }
    },
    resetList() {
      this.resetSearch();
    },
    async startToDistribute() {
      if (!this.selectedCustomerCount) return;
      const result = await this.$store.dispatch('coupon/distributeCoupons', {
        subdomain: this.subdomain,
        draftId: this.draftId,
      });
      if (result?.id) {
        this.$emit('update:isOpen', false);
        this.closeConfirmationModal()
        this.$message.showMessageSuccess(`${result?.couponCount}件のクーポン配布を開始しました。`);
        this.checkImportStatus(result?.id)
      }
    },
    customSuccessCallback(data) {
      if (data.couponCount === 1 && data.customerCount === 1) {
        this.$message.showMessageSuccess(`${data.customerCount}件の配布が完了しました。`);
      } else {
        this.$message.showMessageSuccess(`${data.couponCount}個のクーポンを、${data.customerCount}人に配布が完了しました。`);
      }
    },
    openConfirmation() {
      if (this.selectedCustomerCount > 1000) {
        this.$message.showMessageError('1000件より多くの顧客を選択できません。');
        return
      }
      if (this.selectedCustomerCount === 1) {
        this.startToDistribute()
      } else {
        this.confirmationModal = true
      }
    },
    searchCustomer(event) {
      const text = event.target.value;
      clearTimeout(this.typingInterval);
      this.typingInterval = setTimeout(() => {
        if (text?.trim()) {
          this.searchForm.numberLike = text;
          this.search();
        } else {
          this.resetList();
        }
      }, 300);
    },
    resetCheckbox() {
      this.$emit('on-reset-checkbox', true);
    },
    resetData() {
      this.selectedItems = {
        allFlag: false,
        id: [],
      };
      this.typingInterval = null;
      this.draftId = "";
      this.couponCount = 0;
      this.selectedCustomerCount = 0;
      this.selectedCustomerIds = [];
      this.resetList();
      this.$store.commit('customer/RESET_CUSTOMER_LIST');
    },
  },
};
</script>
<style lang="scss" scoped>
.text-count {
  margin-left: auto;
  margin-right: 0;
}
.modal-distribution {
  ::v-deep .modal-panel {
    height: 90vh;
    min-height: 90vh;
  }

  .form-row {
    margin-bottom: 16px;
  }

  ::v-deep .modal-body {
    padding: 32px 32px 16px;
  }

  .scrollX > div {
    height: calc(90vh - 306px);
    overflow: auto;
  }
}
</style>
